import { Injectable, OnDestroy } from '@angular/core';
import { Firestore, collection, query, where, orderBy, collectionData, DocumentData } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore';
import { Subscription } from 'rxjs';

import { collections } from 'src/app/constants';

import { AuthService } from './auth.service';

export interface ServerEvent {
  eventId: string;
  tenantId: string | null;
  userId: string | null;
  type: string;
  data: any;
}

export interface ServerEventSubscription {
  unsubscribe: () => void;
}

type ServerEventCallback = (event: DocumentData) => void;

@Injectable({
  providedIn: 'root',
})
export class ServerEventsService implements OnDestroy {
  private subscription: Subscription;
  private callbacks: Map<string, Set<ServerEventCallback>> = new Map();

  constructor(
    private afs: Firestore,
    private authService: AuthService,
  ) {
    this.subscription = collectionData(
      query(
        collection(this.afs, collections.SERVER_EVENTS),
        where('userId', 'in', ['', this.authService.userId]),
        where('tenantId', '==', this.authService.tenantId),
        where('updatedAt', '>', Timestamp.fromDate(new Date())),
        orderBy('updatedAt', 'asc'),
      ),
      { idField: 'eventId' },
    ).subscribe(
      (events) => {
        for (const event of events) {
          const type = event.type;

          if (typeof type !== 'string') {
            continue;
          }

          const callbacks = this._getCallbacksForType(type);

          callbacks.forEach((callback) => callback(event));
        }
      },
      (err) => {
        console.error('Error listening for events', err);
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private _getCallbacksForType(type: string) {
    if (!this.callbacks.get(type)) {
      this.callbacks.set(type, new Set());
    }

    return this.callbacks.get(type);
  }

  private _subscribe(type: string, callback: ServerEventCallback) {
    const callbacks = this._getCallbacksForType(type);

    callbacks.add(callback);

    return {
      unsubscribe: () => callbacks.delete(callback),
    };
  }

  subscribe(types: string | string[], callback: ServerEventCallback) {
    if (typeof types === 'string') {
      types = [types];
    }

    const subscriptions = types.map((type) => this._subscribe(type, callback));

    return {
      unsubscribe: () => {
        subscriptions.forEach((subscription) => subscription.unsubscribe());
      },
    };
  }
}
